const LAYOUT = () => import("@/layout/index.vue");

const devTools = [
    {
        path: "/devtools",
        name: "DevTools",
        component: LAYOUT,
        redirect: "/notFound",
        children: [
            {
                path: "",
                name: "clueInfo",
                component: () => import("@/views/tools/project-entry-dev/index"),
                meta: {
                    title: "HOSS/CRM开发者工具",
                },
            },
        ],
    },
];
export default devTools;
