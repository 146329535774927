const LAYOUT = () => import("@/layout/index.vue");

const hp = [
    {
        path: "/hp",
        name: "Hp",
        component: LAYOUT,
        redirect: "/notFound",
        children: [
            {
                path: "statistics-team",
                name: "StatisticsTeam",
                component: () => import("@/views/hp/statistics-team/index"),
                meta: { title: "运营数据" },
            },
            {
                path: "allot",
                name: "Allot",
                component: () => import("@/views/hp/allot-rule/index"),
                meta: { title: "飞鱼分配规则" },
            },
            {
                path: "statistic",
                name: "Statistic",
                component: () => import("@/views/hp/statistic/index"),
                meta: { title: "统计分析" },
            },
            {
                path: "statistic-list",
                name: "StatisticList",
                component: () => import("@/views/hp/statistic/list"),
                meta: { title: "统计列表" },
            },
        ],
    },
];
export default hp;
