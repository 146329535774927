import request from "./request";

// 会跑userId模糊查询
export function getHPUserList(keyword) {
    return request({
        method: "get",
        url: `/emp/queryUserLiteListByKeywordLike?keyword=${keyword}`,
    });
}

// 查询字典(全部)
export function getDataDict() {
    return request({
        method: "json",
        url: `/data_dict/listActive`,
    });
}

// 查询所有大区和人员
export function queryWithPartList(data = "") {
    return request({
        method: "get",
        url: "/emp/app/queryWithPartList" + data,
    });
}

// 上传文件
export function uploadFile(file) {
    let FD = new FormData();
    FD.append("file", file);
    return request({
        method: "post-File",
        url: `/upload/uploadFile`,
        data: FD,
    });
}

// 上传文件
export function getUserInfo() {
    return request({
        method: "get",
        url: `emp/app/current`,
    });
}
