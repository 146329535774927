const LAYOUT = () => import("@/layout/index.vue");

const crm = [
    {
        path: "/crm",
        name: "Crm",
        component: LAYOUT,
        redirect: "/notFound",
        children: [
            {
                path: "clueInfo",
                name: "clueInfo",
                component: () => import("@/views/CRM/clue/info.vue"),
                meta: {
                    title: "线索详情",
                    specialBack: 1, //特殊标题背景
                },
            },
            {
                path: "customerInfo",
                name: "customerInfo",
                component: () => import("@/views/CRM/customer/info.vue"),
                meta: {
                    title: "客户详情",
                    specialBack: 1, //特殊标题背景
                },
            },
            {
                path: "clueEdit",
                name: "clueEdit",
                component: () => import("@/views/CRM/clue/edit.vue"),
                meta: {
                    title: "编辑客户",
                },
            },
            {
                path: "clueList",
                name: "clueList",
                component: () => import("@/views/CRM/clue/list.vue"),
                meta: {
                    title: "客户列表",
                    keepAlive: true,
                },
            },
            {
                path: "businessInfo",
                name: "businessInfo",
                component: () => import("@/views/CRM/business/info.vue"),
                meta: {
                    title: "商机详情",
                    specialBack: 1, //特殊标题背景
                },
            },
            {
                path: "businessEdit",
                name: "businessEdit",
                component: () => import("@/views/CRM/business/edit.vue"),
                meta: {
                    title: "编辑商机",
                },
            },
            {
                path: "customerEdit",
                name: "customerEdit",
                component: () => import("@/views/CRM/customer/edit.vue"),
                meta: {
                    title: "编辑客户",
                },
            },
            {
                path: "businessList",
                name: "businessList",
                component: () => import("@/views/CRM/business/list.vue"),
                meta: {
                    title: "商机列表",
                    keepAlive: true,
                },
            },
        ],
    },
];
export default crm;
