import {isAndroid, isIOS} from "./index";

// 获取皮肤
export const hpGetSkinType = () => {
    try {
        if (isIOS()) {
            window.webkit.messageHandlers?.hpGetSkinType.postMessage(null);
        } else if (isAndroid()) {
            window.Android?.hpGetSkinType(JSON.stringify(null));
        }
    } catch (err) {
        console.error("调用获取皮肤缓存报错");
    }
};

// 获取token
export const getHossToken = (name) => {
    if (isIOS()) {
        window.webkit.messageHandlers?.getHossToken.postMessage(name);
    } else if (isAndroid()) {
        window.Android?.getHossToken(JSON.stringify(name));
    }
};

// 删除缓存
export const hossFileUploaded = () => {
    try {
        if (isAndroid()) window.Android?.hossFileUploaded();
    } catch (err) {
        console.error("调用删除图片缓存报错");
    }
};

// app调用返回
export const hossExit = () => {
    console.log("开始调用返回首页方法")
    try {
        if (isIOS()) window.webkit.messageHandlers?.hossExit.postMessage(null);
        else if (isAndroid()) window.Android?.hossExit();
    } catch (err) {
        console.error("调用返回首页方法报错");
        // 回到首页 /notFound
        window.location.href = "/notFound";
    }
};

// 调起电话
export const callPhone = (phone, callBack) => {
    window.hossCallPhoneCallBack = callBack;
    try {
        if (isIOS())
            window.webkit.messageHandlers?.keepRunGo.postMessage({
                action: "HpCall",
                id: phone + "",
            });
        else if (isAndroid())
            window.Android?.keepRunGo(JSON.stringify({action: "HpCall", id: parseInt(phone)}));
    } catch (err) {
        console.error("调用拨打电话方法报错");
    }
};

export const shareWXMiniGram = (title, content, imgUrl, miniAppId, miniAppPath) => {
    try {
        var idDict = {
            title: title,
            content: content,
            imgUrl: imgUrl,
            miniAppId: miniAppId,
            miniAppPath: miniAppPath,
        }
        if (isIOS())
            window.webkit.messageHandlers?.keepRunGo.postMessage({
                action: "HuiPaoShareMiniGram",
                id: JSON.stringify(idDict),
            });
        else if (isAndroid())
            window.Android?.keepRunGo(JSON.stringify({
                action: "HuiPaoShareMiniGram", id:
                    JSON.stringify(idDict)
            }));
    } catch (err) {
        console.error("调用分享微信小程序方法报错");
    }
};

export const shareRecordToWXMiniGram = (clientId, timeMS) => {
    const miniAppId = 'gh_cbc7fddb2a5b'
    const imgUrl = 'http://huipao-paint.huipao.net/recordShareToWXCover?client_id=' + clientId + '&time_ms=' + timeMS
    // 毫秒转日期 6.18 日
    const date = new Date(timeMS)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const timeMSDate = month + '.' + day
    const title = '[恩璞健康]' + timeMSDate + '实施记录报告'
    const content = ''
    const miniAppPath = 'pages/index/index?clientId=' + clientId + '&timeMS=' + timeMS
    shareWXMiniGram(title, content, imgUrl, miniAppId, miniAppPath)
}

// 改变头部字体颜色
export const setNativeColor = (isDark) => {
    try {
        if (isIOS()) {
            window.webkit.messageHandlers?.controlAppStatusBarTextColor.postMessage({isDark});
        } else if (isAndroid()) {
            window.Android?.controlAppStatusBarTextColor(isDark);
        }
    } catch (ex) {
        console.log("改变头部颜色异常");
    }
};

// 获取位置信息
export const getLocal = () => {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            clearTimeout(timer);
            delete window.setAppData;
            reject("获取位置信息超时");
        }, 3000);
        try {
            if (isIOS()) {
                window.webkit.messageHandlers?.keepRunGetData.postMessage(null);
            } else if (isAndroid()) {
                window.Android?.keepRunGetData();
            }
            window.setAppData = (res) => {
                if (typeof res == "string") res = JSON.parse(res);
                resolve(res);
                window.setAppData = null;
            };
        } catch (e) {
            reject("位置信息获取失败");
        }
    });
};
