const LAYOUT = () => import("@/layout/index.vue");

const hoss = [
    {
        path: "/hoss",
        name: "Hoss",
        component: LAYOUT,
        redirect: "/notFound",
        children: [
            {
                path: "clientList",
                name: "ClientList",
                component: () => import("@/views/hoss/client-list"),
                meta: {
                    title: "客户列表",
                    keepAlive: true
                },
            },
            {
                path: "clientRecord",
                name: "ClientRecord",
                component: () => import("@/views/hoss/client-record"),
                meta: {title: "客户档案"},
            },
            {
                path: "recordList",
                name: "record-list",
                component: () => import("@/views/hoss/client-record/record-list"),
                meta: {title: "新建客户"},
            },
            {
                path: "stageInfo",
                name: "StageInfo",
                component: () => import("@/views/hoss/stage-info/index"),
                meta: {title: "客户详情"},
            },
            {
                path: "createTeam",
                name: "CreateTeam",
                component: () => import("@/views/hoss/team/create-team"),
                meta: {title: "分配团队与阶段"},
            },
            {
                path: "changeTeam",
                name: "ChangeTeam",
                component: () => import("@/views/hoss/team/change-team"),
                meta: {title: "团队调整"},
            },
            {
                path: "icebreaking",
                name: "Icebreaking",
                component: () => import("@/views/hoss/icebreaking/index"),
                meta: {title: "破冰记录", keepAlive: true},
            },
            {
                path: "implementation",
                name: "Implementation",
                component: () => import("@/views/hoss/implementation/index"),
                meta: {title: "实施记录", keepAlive: true},
            },
            {
                path: "reviewSummary",
                name: "ReviewSummary",
                component: () => import("@/views/hoss/review-summary/index"),
                meta: {title: "复盘总结"},
            },
            {
                path: "fanGroupReport",
                name: "FanGroupReport",
                component: () => import("@/views/hoss/fan-group-report/index"),
                meta: {title: "粉丝群报告"},
            },
            {
                path: "importantNode",
                name: "ImportantNode",
                component: () => import("@/views/hoss/important-node/list"),
                meta: {title: "重要节点记录"},
            },
            {
                path: "addImportantNode",
                name: "AddImportantNode",
                component: () => import("@/views/hoss/important-node/add"),
                meta: {title: "添加重要节点"},
            },
            {
                path: "application",
                name: "ApplicationPage",
                component: () => import("@/views/hoss/application-page/index"),
            },
            {
                path: "approval-record-page",
                name: "ApprovalRecordPage",
                component: () => import("@/views/hoss/approval-record-page/index"),
                meta: {title: "审批记录"},
            },
            {
                path: "riskList",
                name: "RiskList",
                component: () => import("@/views/hoss/risks/risk-list"),
                meta: {title: "风险记录"},
            },
            {
                path: "setRisk",
                name: "SetRisk",
                component: () => import("@/views/hoss/risks/set-risk"),
                meta: {title: "设置风险"},
            },
            {
                path: "txMap",
                name: "TxMap",
                component: () => import("@/views/hoss/tx-map.vue"),
                meta: {title: "选择位置", navBarTightText: "确定"},
            }
        ],
    },
];
export default hoss;
