import dayjs from "dayjs";

// 时间格式化
export function timeFormat(t, format = "YYYY-MM-DD") {
    if (!t) return "";
    return dayjs(t).format(format);
}

// 判断是否是安卓
export function isAndroid() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    return isAndroid || "";
}

//判断是否是iOS
export function isIOS() {
    let u = navigator.userAgent;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isIOS || "";
}

// 防抖
export function debounce(func, delay = 300) {
    var timeout;
    return function (e) {
        clearTimeout(timeout);
        var context = this,
            args = arguments;
        timeout = setTimeout(function () {
            func.apply(context, args);
        }, delay);
    };
}

//乘法
export function accMul(arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
    }
    return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
}

//加法
export function accAdd(arg1, arg2) {
    var r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
}

//减法
export function Subtr(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 除法
export function accDiv(value1, value2) {
    if (value1 == 0) return 0;

    let v1 = value1.toString();
    let v2 = value2.toString();
    let m1 = v1.split(".")[1] ? v1.split(".")[1].length : 0;
    let m2 = v2.split(".")[1] ? v2.split(".")[1].length : 0;
    let _v1 = Number(v1.replace(".", ""));
    let _v2 = Number(v2.replace(".", ""));
    return accMul(_v1 / _v2, Math.pow(10, m2 - m1));
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
    let date;
    if (typeof time === "object") {
        date = time;
    } else {
        if (typeof time === "string") {
            if (/^[0-9]+$/.test(time)) {
                // support "1548221490638"
                time = parseInt(time);
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), "/");
            }
        }

        if (typeof time === "number" && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
            return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
    });
    return time_str;
}

export function parseLastDate(curTime) {
    var m1 = dayjs(curTime);
    var m2 = dayjs();
    var val = m2.diff(m1, "minute");
    var str = "";
    if (val > 60 && val < 1440) {
        str = (val / 60).toFixed(0) + "小时前";
    } else if (val > 1440) {
        str = (val / 1440).toFixed(0) + "天前";
    } else {
        str = val + "分钟前";
    }

    return str;
}

// 计算时间差
export function getAge(time) {
    time = dayjs(time).format("YYYY-MM-DD").split("-");
    // 新建日期对象
    let date = new Date();
    // 今天日期，数组，同 time
    let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    // 分别计算年月日差值
    let age = today.map((value, index) => {
        return value - time[index];
    });
    // 当天数为负数时，月减 1，天数加上月总天数
    if (age[2] < 0) {
        // 简单获取上个月总天数的方法，不会错
        let lastMonth = new Date(today[0], today[1], 0);
        age[1]--;
        age[2] += lastMonth.getDate();
    }
    // 当月数为负数时，年减 1，月数加上 12
    if (age[1] < 0) {
        age[0]--;
        age[1] += 12;
    }
    return age;
}

// 获取字典
import {getDataDict} from "@/api/common";

export async function getDict() {
    let dictDate = sessionStorage.getItem("hoss-dict") || "";

    if (!dictDate) {
        const {value} = await getDataDict();
        const treeData = recursionTree(value, "id", "parentId", null, "children", {});

        const data = {};
        for (let i = 0; i < treeData.length; i++) {
            data[treeData[i]["code"]] = treeData[i];
        }
        sessionStorage.setItem("hoss-dict", JSON.stringify(data));
        return data;
    } else {
        return JSON.parse(dictDate);
    }
}

// 获取字典
import {getUserInfo as getInfo} from "@/api/common";

export async function getUserInfo() {
    let userInfo = sessionStorage.getItem("hoss-user") || "";

    if (!userInfo) {
        const {value} = await getInfo();
        sessionStorage.setItem("hoss-user", JSON.stringify(value));
        return value;
    } else {
        return JSON.parse(userInfo);
    }
}

// 提取字典数据
export const recursionTree = (
    source = [],
    idField = "id",
    parentIdField = "parentId",
    parentIdNoneValue = "",
    childrenField = "children",
    treeOption = undefined
) => {
    const treeOptions = {
        enable: false, // 是否开启转tree插件数据
        keyField: "key", // 标识字段名称，默认为key
        valueField: "value", // 值字段名称，默认为value
        titleField: "title", // 标题字段名称，默认为title

        keyFieldBind: "id", // 标识字段绑定字段名称，默认为id
        valueFieldBind: "id", // 值字段名称绑定字段名称，默认为id
        titleFieldBind: "name", // 标题字段名称绑定字段名称，默认为name
    };
    // 合并tree树形配置
    if (treeOption) {
        Object.assign(treeOptions, treeOption);
    }

    // 对源数据深度克隆
    const cloneData = JSON.parse(JSON.stringify(source));
    return cloneData.filter((parent) => {
        // 返回每一项的子级数组

        const branchArr = cloneData.filter((child) => parent[idField] === child[parentIdField]);

        // 绑定tree树形配置
        if (treeOptions.enable) {
            branchArr.map((child) => {
                child[treeOptions.keyField] = child[treeOptions.keyFieldBind];
                child[treeOptions.valueField] = child[treeOptions.valueFieldBind];
                child[treeOptions.titleField] = child[treeOptions.titleFieldBind];
                return child;
            });
        }

        // 如果存在子级，则给父级添加一个children属性，并赋值，否则赋值为空数组
        if (branchArr.length > 0) {
            parent[childrenField] = branchArr;
        } else {
            parent[childrenField] = [];
        }

        // 绑定tree树形配置
        if (treeOptions.enable) {
            parent[treeOptions.keyField] = parent[treeOptions.keyFieldBind];
            parent[treeOptions.valueField] = parent[treeOptions.valueFieldBind];
            parent[treeOptions.titleField] = parent[treeOptions.titleFieldBind];
        }

        return parent[parentIdField] == parentIdNoneValue; // 返回第一层
    });
};
