console.log("WebView项目初始化");
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import eventBus from "@/utils/event";

import "@/utils/rem.js";
import "@/assets/css/global.css";
import "@/assets/css/theme.css";
import "@/utils/vant.js";
import "@/assets/hpIconFont/iconfont.js";
import VueClipboard from "vue-clipboard2"; // 复制功能
import {hpGetSkinType} from "@/utils/nativeMethods"; // 调用方法获取皮肤
import {getDict, getUserInfo} from "./utils";
import {Col, Row} from 'vant';

async function bootstrap() {
    window.hpSetSkinTypeCallback = (theme) => {
        console.log(`接收原生皮肤设置回调theme: ${theme}`);
        if (!theme || (theme != "DARK_MODE" && theme != "SUN_MODE")) {
            theme = "SUN_MODE";
        }
        localStorage.setItem("hoss-theme", theme);
        document.body.className = theme;
        Vue.prototype.$theme = theme;
    };
    console.log(`调用原生方法获取皮肤类型`);
    hpGetSkinType(); // 调用原生方法获取皮肤类型
    document.body.className = localStorage.getItem("hoss-theme") || "SUN_MODE"; // 默认添加白色
    Vue.prototype.$theme = localStorage.getItem("hoss-theme") || "SUN_MODE";

    Vue.use(VueClipboard);
    Vue.use(eventBus);
    Vue.use(Col);
    Vue.use(Row);
    Vue.config.productionTip = false;
    Vue.prototype.$getDict = getDict; // 获取字典
    Vue.prototype.$getUserInfo = getUserInfo; // 获取用户信息
    new Vue({
        router,
        render: (h) => h(App),
    }).$mount("#app");
}

bootstrap();
