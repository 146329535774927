import Vue from "vue";

import {
    Button,
    Image as VanImage,
    Tabs,
    Tab,
    Form,
    Popup,
    Field,
    CellGroup,
    RadioGroup,
    Radio,
    DatetimePicker,
    List,
    Tag,
    Icon,
    Cell,
    Calendar,
    Picker,
    Cascader,
    Stepper,
    Uploader,
    Area,
    Steps,
    Step,
    SwipeCell,
    Toast,
    Dialog,
    DropdownMenu,
    DropdownItem,
    Collapse,
    CollapseItem,
    Rate,
    NavBar,
    Divider,
    Empty,
    PullRefresh,
    Sidebar,
    SidebarItem,
    Search,
    Checkbox,
    ActionSheet,
    CheckboxGroup,
    TabbarItem,
    Tabbar,
    Loading,
    Progress,
    Swipe,
    SwipeItem,
    Popover,
} from "vant";

Toast.setDefaultOptions("loading", { forbidClick: true, duration: 5000 });

Vue.use(Button)
    .use(Tabs)
    .use(Tab)
    .use(Form)
    .use(Popup)
    .use(Field)
    .use(CellGroup)
    .use(RadioGroup)
    .use(Radio)
    .use(DatetimePicker)
    .use(List)
    .use(Tag)
    .use(Icon)
    .use(Cell)
    .use(Calendar)
    .use(Picker)
    .use(Cascader)
    .use(Stepper)
    .use(Uploader)
    .use(Area)
    .use(Steps)
    .use(Step)
    .use(SwipeCell)
    .use(Toast)
    .use(Dialog)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Collapse)
    .use(CollapseItem)
    .use(Rate)
    .use(NavBar)
    .use(Divider)
    .use(Empty)
    .use(PullRefresh)
    .use(Sidebar)
    .use(SidebarItem)
    .use(Search)
    .use(Checkbox)
    .use(ActionSheet)
    .use(CheckboxGroup)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Loading)
    .use(VanImage)
    .use(Progress)
    .use(Swipe)
    .use(SwipeItem)
    .use(Popover);
