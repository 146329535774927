<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.path"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.path"></router-view>
  </div>
</template>
<script src="//at.alicdn.com/t/font_3416082_m42cw66y2zg.js"></script>
<script>
export default {
  created() {
    console.log("%c 项目打开", "color:green;font-weight:600;font-size:18px");
  },
};
</script>

<style>
.van-overlay {
  /* background-color: ; */
}
</style>
