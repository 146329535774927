import {getHossToken} from "@/utils/nativeMethods";
import Base64 from "@/utils/Base64";
import axios from "axios";
import Qs from "qs";

const base64 = new Base64();

function randomString(e = 5) {
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
}

// 记录每一次请求的秒级时间戳
let times = [];

export default () => {
    const now = parseInt(+new Date() / 1000);
    times = times.filter((item) => now - item < 60); // 筛选出一分钟内的时间
    times.push(now);
    if (times.length >= 10) return Promise.reject("获取登录信息频繁，请稍后再试"); // 一分钟内请求超过10次

    return new Promise((resolve, reject) => {
        const callbackName = `getHossTokenCallback${randomString()}`;
        const timer = setTimeout((_) => {
            clearTimeout(timer);
            delete window[callbackName];
            reject("获取身份信息超时");
        }, 12000);

        try {
            getHossToken({callbackName});
            window[callbackName] = (res) => {
                clearTimeout(timer);
                if (typeof res == "string") res = JSON.parse(res);
                const {token, machineCode, success} = res;
                if (success) {
                    const str = `Bearer ${base64.encode(token + ":" + machineCode)}`;
                    sessionStorage.setItem("token", str);
                    resolve(str);
                } else {
                    reject("获取登录状态失败");
                }
                delete window[callbackName];
            };
        } catch (error) {
            clearTimeout(timer);
            delete window[callbackName];
            reject("获取登录状态失败");
        }
    });
};

if (process.env.NODE_ENV === "development") {
    window._t = async (userId, machineCode = 123) => {
        const {data} = await axios({
            method: "post",
            url: `/user/login/hoss/app`,
            data: Qs.stringify({machineCode, userId}),
        });
        const token = data.value.token;
        sessionStorage.setItem("token", `Bearer ${base64.encode(token + ":" + machineCode)}`);
    };
}
