import Vue from "vue";
import VueRouter from "vue-router";

import crm from "./modules/crm";
import hoss from "./modules/hoss";
import hp from "./modules/hp";
import devTools from "./modules/devTools";
import huipaoAi from "./modules/huipao-ai"

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        ...crm,
        ...hoss,
        ...hp,
        ...huipaoAi,
        ...(process.env.NODE_ENV === "development" ? devTools : []),
        {
            path: "/:all(.*)*",
            name: "NotFound",
            component: () => import("@/layout/index.vue"),
            meta: {title: "ErrorPage"},
            children: [
                {
                    path: "/:all(.*)*",
                    component: () => import("@/views/notFound.vue"),
                },
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0,
            }; //期望滚动到哪个的位置
        }
    },
});

export default router;
