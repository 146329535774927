/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import Qs from "qs";
import {Toast} from "vant";
import getAppToken from "./utils/getToken";

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = async (config, response) => {
    switch (response.status) {
        case 400:
            Toast.fail("请求错误(400)，请检查网络或联系管理员！");
            break;
        case 401:
            switch (response.data.code) {
                case "10001":
                    sessionStorage.removeItem("token");
                    return instance(config);
                case "10002":
                    Toast.fail("用户权限错误，请检查网络或联系管理员！");
                    break;
                case "10003":
                    Toast.fail("找不到对应资源，请检查网络或联系管理员！");
                    break;
                default:
                    Toast.fail("未授权，请重新登录(401)，请检查网络或联系管理员！");
                    break;
            }
            break;
        case 403:
            Toast.fail("拒绝访问(403)，请检查网络或联系管理员！");
            break;
        case 404:
            Toast.fail("请求出错(404)，请检查网络或联系管理员！");
            break;
        case 408:
            Toast.fail("请求超时(408)，请检查网络或联系管理员！");
            break;
        case 500:
            Toast.fail("服务器错误(500)，请检查网络或联系管理员！");
            break;
        case 501:
            Toast.fail("服务未实现(501)，请检查网络或联系管理员！");
            break;
        case 502:
            Toast.fail("网络错误(502)，请检查网络或联系管理员！");
            break;
        case 503:
            Toast.fail("服务不可用(503)，请检查网络或联系管理员！");
            break;
        case 504:
            Toast.fail("网络超时(504)，请检查网络或联系管理员！");
            break;
        case 505:
            Toast.fail("HTTP版本不受支持(505)，请检查网络或联系管理员！");
            break;
        default:
            Toast.fail(`连接出错(${response.status})，请检查网络或联系管理员！`);
            break;
    }
};

// 创建axios实例
var instance = axios.create({
    timeout: 1000 * 12,
    baseURL: "/api",
});

const request = function ({method = "get", url, data = {}}) {
    let result;
    switch (method) {
        case "get":
            result = instance.get(url, data);
            break;
        case "json":
            result = instance.post(url, data);
            break;
        case "post":
            result = instance.post(url, Qs.stringify(data), {
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
            });
            break;
        case "post-File":
            result = instance.post(url, data, {
                headers: {"Content-Type": "multipart/form-data"},
            });
            break;
    }
    return result;
};
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(async (config) => {
    let token = sessionStorage.getItem("token");
    if (!token) {
        console.log("%c token不存在，获取token", "color:green;font-weight:600;font-size:18px");
        token = await getAppToken();
        console.log(`%c 获取到token=${token}`, "color:green;font-weight:600;font-size:18px");
    }
    config.headers.Auth = token;
    return config;
});

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    (res) => {
        if (!res.data.success && res.data.code !== 0) {
            Toast.fail(res.data.message || res.data.msg || "请求失败");
            return Promise.reject(res);
        }
        return Promise.resolve(res.data);
    },
    // 请求失败
    (err) => {
        // 请求已发出，但是不在2xx的范围
        if (err.message?.indexOf("timeout") >= 0) {
            Toast.fail("请求超时");
        } else if (err.response) {
            errorHandle(err.config, err.response);
        } else {
            Toast.fail(err);
        }
        return Promise.reject(err.response || err);
    }
);

export default request;
