const huipaoAi = [
    {
        path: '/huipaoAi',
        name: 'HuipaoAI',
        // 没有父布局
        component: () => import('@/layout/index.vue'),
        children: [
            {
                path: 'index',
                name: 'HuipaoAIIndex',
                component: () => import('@/views/huipao-ai/index'),
                meta: {title: '会跑AI'}
            }
        ]
    }
];

export default huipaoAi;
